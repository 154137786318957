import { Component, Input } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { TranslateModule } from "@ngx-translate/core";
import { FlipBoxComponent } from "../../components/flip-box/flip-box.component";
import { NewsItemComponent } from "../../components/news-item/news-item.component";
import { NgForOf, NgIf, NgTemplateOutlet } from "@angular/common";
import { JobGroupComponent } from "../careers/job-item/job-group.component";
import { NewsItemDto } from "../../contracts/news-item.dto";
import { Router } from '@angular/router';
import { RouteMetaService } from '../../services/route-meta.service';
import { UrlUtil } from '../../utils/url.util';
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'app-news-detail',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, FlipBoxComponent, NewsItemComponent, NgTemplateOutlet, JobGroupComponent, NgForOf, NgIf],
  templateUrl: './news-detail.component.html'
})
export class NewsDetailComponent {
  newsItem: NewsItemDto;
  @Input() showDesc?: boolean = true;

  constructor(
    private seo: RouteMetaService,
    private newsService: NewsService,
    private router: Router
  ) {
    const pathname = this.router.url;
    const slug = UrlUtil.getRouteParam(pathname, 1);
    const items = this.newsService.getNews();

    this.newsItem = items.find(x => x.slug == slug);
    let url = UrlUtil.composeUrlRoute('news', this.newsItem.slug);

    this.seo.updateSeo({
      description: this.newsItem.description,
      ogImage: UrlUtil.composeUrlRoute(this.newsItem.imgSrc),
      ogUrl: url,
      title: this.newsItem.title,
      url: url
    });
  }

}
