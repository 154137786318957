<app-news-detail [showDesc]="true">
  <div class="row align-items-center g-5  pb-5">
    <div class="col-sm-12 col-xl-4 col-lg-6 bio-img">
      <img alt="Alexander Buresch" src="/assets/images/biography/alexander_buresch.webp"
        class="d-block img-fluid half-img-text bio-portrait" loading="lazy">
    </div>
    <div class="col-xl-8 col-lg-6">
      <h3 class=" lh-1 mb-3">
        Alexander Buresch - SVP & CIO BMW Group
      </h3>
      <p class="lead">
        {{ 'biography.buresch' | translate }}
      </p>
    </div>
  </div>

  <app-photo-gallery [images]="images" />
</app-news-detail>
