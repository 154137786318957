<div class="hero-bg hero-bg-news">
  <app-navbar />
  <header class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container  hero-text  mx-0 ">
      <div>
        <p class="new-line">{{ jobDetail.longDesc }}</p>
        <h1 class="new-line">{{ jobDetail.title }}</h1>
      </div>
    </div>
  </header>
</div>

<div class="app-container about-values pt-4 pb-5">
  <div class="about-text pt-4">
    <b>Who we are </b>
    <p>
      Everything at BMW TechWorks begins with passion. It transforms a job into a calling. It motivates us to
      continually reinvent mobility and bring new ideas to life and onto the roads.
      Passion for collaborative projects turns a team into a strong unit where every viewpoint is appreciated.
    </p>
    <p>
      Whatever you aspire to, at BMW TechWorks we live and breathe software and we offer a wide variety of departments
      and fields where you can share your professional passion with us.
      By delivering software all the way from the idea to the final product, we lead BMW into the future of
      digitalization and mobility.
      And this also means:
    </p>
    <ul>
      <li>We search for excellence </li>
      <li>We get involved </li>
      <li>We keep it simple </li>
      <li>We share trust </li>
    </ul>
    <p>
      Join BMW TechWorks on our journey of innovation and excellence as we redefine the future of motion, one product at
      a time.
      Our engineers are vital to the innovation and digitalization of BMW Group's driving machines, production, sales
      and banking processes, and you could be part of our evolving teams.
    </p>
    <b>What awaits you </b>
  </div>
  <div class="about-text pt-4">
    <ng-content></ng-content>
  </div>
  <div class="about-text pt-4">
    <b>What do we offer?  </b>
    <ul>
        <li>Challenging projects with which we are shaping the mobility of tomorrow  </li>
        <li>Great team spirit within an international team  </li>
        <li>State-of-the-art global IT landscape and processes  </li>
        <li>100% implementation of the Agile Working Model methodology   </li>
        <li>Wide range of personal & professional development opportunities  </li>
        <li>High level of job security </li>
        <li>Work-Life-Balance and flexible working hours </li>
        <li>Attractive and performance-related remuneration </li>
        <li>Dynamic and highly-motivating work environment </li>
    </ul>
    
    <p>
        Are you excited by new technologies and an innovative environment? Apply now at <a href="mailto:careers@bmwtechworks.ro">careers&#64;bmwtechworks.ro</a> !
    </p>
    <p>
        At BMW TechWorks, we view diversity and inclusion in all its forms as a cornerstone of our team's strength.
        Equal opportunities are a priority, and fair treatment of all applicants and employees is a fundamental principal of our corporate policy. Our recruitment decisions ensure a balanced and inclusive approach, valuing our people’s different personalities and skills, experience and viewpoints, regardless of sexual orientation, national origin or citizenship, gender identity, age, race, color, ethnicity, religion, or disability.
    </p>
    
  </div>
</div>