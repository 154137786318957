import { NgForOf, NgTemplateOutlet } from "@angular/common";
import { Component } from "@angular/core"; 
import {TranslateModule} from "@ngx-translate/core"; 
import { NavbarComponent } from "../../../components/navbar/navbar.component";
import { FlipBoxComponent } from "../../../components/flip-box/flip-box.component";
import { JobGroupComponent } from "../job-item/job-group.component";
import { JobItemDto } from "../../../contracts/jobItemDto";
import { RouteMetaService } from "../../../services/route-meta.service";
import { Router } from "@angular/router";
import { UrlUtil } from "../../../utils/url.util";
import { CareerService } from "../../../services/career.service";

@Component({
    selector: 'app-job-detail',
    standalone: true,
    imports: [TranslateModule, NavbarComponent, 
      FlipBoxComponent, NgTemplateOutlet, JobGroupComponent, NgForOf, JobDetailComponent],
    templateUrl: './job-detail.component.html' 
  })
  export class JobDetailComponent{
    jobDetail:JobItemDto;

    constructor(
      private seo:RouteMetaService,
      private careerService:CareerService,
      private router:Router 
    ) {
      const pathname = this.router.url;
      const slug = UrlUtil.getRouteParam(pathname, 1);
      const items = this.careerService.getFlatList();
  
      this.jobDetail = items.find(x=> x.slug == slug);
      const url = UrlUtil.composeUrlRoute('job', this.jobDetail.slug);
      this.seo.updateSeo({
        description: 'BMW Techworks Job ',
        ogImage: UrlUtil.composeUrlRoute(this.jobDetail.imgSrc),
        ogUrl:  url,
        title: this.jobDetail.title,
        url: url
      }); 
    }
  }