<app-job-detail>
    <ul>
        <li>Being a passionate DevOps Consultant, you care about technical support and maintenance activities for the applications that we are responsible for </li>
        <li>
            Work with the PIC Processes of the customer (Incident, Problem, and Change Management)
        </li>
        <li> Communicate and cooperate with the users and the customer </li>
        <li>  Participate in daily meetings with the team, weekly meetings with the customer   </li>
        <li>  Perform problem analysis and documentation, root cause analysis, logs analysis  </li>
        <li>  Solve the issues and tickets that arise in daily business </li>
        <li> Find performance issues in time and solving the problems proactively  </li>
        <li> Communication with 1st level/3rd level and other support and development groups </li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or a related field </li>
        <li>Minimum 3-5 years of experience in the application management services field with Windows, Linux and Oracle SQL </li>
        <li>Experience with AWS would be nice to have </li>
        <li>ITIL Certification represents a plus  </li>
        <li>Experience in the automotive industry is nice to have </li>
        <li>Excellent command of both spoken and written English and German </li>
    </ul> 
</app-job-detail>