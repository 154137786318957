import { NgForOf } from "@angular/common";
import { Component, Input } from "@angular/core";
import * as baguetteBox from 'baguettebox.js';
import { GalleryItemModel } from "./models/gallery-item.model";
import { GuidUtils } from "../../utils/guid.utils";

@Component({
  selector: 'app-photo-gallery',
  standalone: true,
  imports: [NgForOf],
  templateUrl: './photo-gallery.component.html'
})
export class PhotoGallery {
  @Input() images: GalleryItemModel[] = [];
  id: string = GuidUtils.newId();

  ngAfterViewInit(): void {
    baguetteBox.run('.gallery');
  }

  getImgSrc(img: GalleryItemModel, full: boolean) {
    if (img.hasThumb && full == false)
      return img.thumbFileName;

    return img.fullSizeName;
  }

}
