<div *ngIf="opts" class="tw-modal" (click)="closeDialog()">
  <div class="tw-modal-content">
    <button class="tw-modal-close" (click)="closeDialog()">×</button>
    <div class="tw-modal-body" (click)="preventClose($event)">
      <h2 class="tw-modal-title">{{ opts.title }}</h2>
      <div class="dynamic-content" [innerHTML]="opts.content">

      </div>
    </div>
  </div>
</div>
