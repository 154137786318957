<div class="hero-bg hero-bg-news">
  <app-navbar />
  <header class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container  hero-text  mx-0 ">
      <div>
        <p class="new-line">{{ newsItem.subTitle }}</p>
        <h1 class="new-line">{{ newsItem.title }}</h1>
      </div>
    </div>
  </header>
</div>

<div class="app-container about-values pt-4 pb-5">
  <h3 class="about-text pt-4" *ngIf="showDesc" innerHTML="{{ newsItem.description }}"></h3>
  <div class="about-text pt-4">
    <ng-content></ng-content>
  </div>
</div>