<app-job-detail>
    <ul>
        <li>Being a passionate Java Developer, you care about defining tech stack for business applications together
            with our team of software architects </li>
        <li> Developing business logic based on JEE using applications servers (Payara, Quarkus) </li>
        <li> Define and document data models for the applications </li>
        <li> Working in an Agile team using Scrum methodology and their derivatives (SaFE, LeSS) </li>
        <li> Using test driven development techniques </li>
        <li> Using and maintaining a CI/CD pipeline </li>
        <li> Deploying the application to a Kubernetes cluster </li>
        <li> End-to-end responsibility for application development </li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or a related field </li>
        <li>At least 5 years of experience on a similar Java Developer role is required </li>
        <li>Advanced knowledge of OOP, Algorithms & Data Structures </li>
        <li>Code principles & design patterns know-how </li>
        <li>Experience in development with Java SE 8+ </li>
        <li>JUnit experience and knowledge </li>
        <li>Relational Database concepts and usage (PostgreSQL or other), NoSQL concepts and usage (MongoDB or other),
            Migration tools (e.g. Flyway) </li>
        <li>Nice to have: AWS, Terraform </li>
        <li>Excellent English verbal and written skills </li>
        <li>German communication skills will be a plus </li>
    </ul>
</app-job-detail>