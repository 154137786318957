import {Component, TrackByFunction} from '@angular/core';
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {TranslateModule} from "@ngx-translate/core";
import {SearchComponent} from "../../components/search/search.component";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {JobGroupComponent} from "./job-item/job-group.component";
import {JobGroupDto} from "../../contracts/job-group.dto";
import {DataService} from "../../providers/data.service";
import { CareerService } from '../../services/career.service';

@Component({
  selector: 'app-careers',
  standalone: true,
  imports: [TranslateModule,
    NavbarComponent,
    SearchComponent, FormsModule, NgForOf, JobGroupComponent, NgIf],
  templateUrl: './careers.component.html',
  styleUrl: './careers.component.scss'
})
export class CareersComponent {
  items: JobGroupDto[] = [];

  constructor(private careerService:CareerService) {
    this.items = this.careerService.getItems();
  }

  trackByFn: TrackByFunction<JobGroupDto> = (ix, it) => {
    return it.name;
  }

}
