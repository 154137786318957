import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from "@angular/router";
import {RouterLinkActive} from "@angular/router";
import {ContactRoute, HomeRoute, AboutRoute, CultureRoute, CareersRoute, NewsRoute} from "../../app.globals";
import {isPlatformServer, NgClass, NgStyle} from "@angular/common";
import {TranslateService} from '@ngx-translate/core';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterLink,
    NgStyle,
    RouterLinkActive,
    TranslateModule,
    RouterOutlet,
    NgClass
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})

export class NavbarComponent {
  protected readonly ContactRoute = ContactRoute;
  protected readonly HomeRoute = HomeRoute;
  protected readonly AboutRoute = AboutRoute;
  protected readonly CultureRoute = CultureRoute;
  protected readonly CareersRoute = CareersRoute;
  protected readonly NewsRoute = NewsRoute;
  public lang = this.translateService.currentLang;

  constructor(private translateService: TranslateService,
              @Inject(PLATFORM_ID) private platformId: any,
              private router: Router) {
    this.translateService.onLangChange.subscribe((params: any) => {
      this.lang = params.lang;
    });
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) return;

    document.querySelector('#navbarSideCollapse')?.addEventListener('click', () => {
      document.querySelector('.offcanvas-collapse')?.classList.toggle('open');
    });
  }

  changeLanguage(language: string) {
    this.lang = language;
    localStorage.setItem('_language', language);
    this.router.navigate([], {
      queryParams: {
        lang: language
      }
    });
  }
}
