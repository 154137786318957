<div class="news-item">
  <div class="left">
    <img [src]="item.imgSrc" [alt]="item.imgAlt" class="img-responsive"/>
  </div>
  <div class="right">
    <div>
      <span class="subtitle">{{ item.subTitle }}</span>
      <h2 class="title">{{ item.title }}</h2>
      <p class="desc" [innerHTML]="item.description">
      </p>
      <div *ngIf="item.files && item.files.length > 0" class="news-item-file-listing">
        <ng-template ngFor let-it [ngForOf]="item.files" let-i="index">
          <app-file-preview [fileName]="it.name" [title]="it.title"/>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row mx-0">
    <button class="call-to-action-link-btn news-button me-2 mt-2" (click)="readMore($event, item)">
      <span>{{ 'button.read-button' | translate }}</span>
      >
    </button>
    <button class="call-to-action-link-btn news-button mt-2" *ngIf="item.private && scrollTargetId"
            (click)="scrollToComponent()">
      <span>{{ 'button.view-photos' | translate }}</span>
      >
    </button>
  </div>
</div>
