import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private base = '/api/file';

  constructor(private http: HttpClient) {
  }

  download(fileName: string) {
    return this.http.get(this.base + '/download?fileName=' + fileName, {responseType: 'blob'});
  }

  download2(fileName: string) {
    return this.http.get(this.base + '/download2?fileName=' + fileName, {responseType: 'blob'});
  }
}
