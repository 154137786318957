import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { ContactRoute, HomeRoute, AboutRoute, CultureRoute, NewsRoute, CareersRoute, LegalRoute, CookiesPolicyRoute, PrivacyPolicyRoute } from "../../app.globals";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, TranslateModule, NgClass],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  copyrightText = 'Copyright Techworks Romania &copy; 2024';
  protected readonly ContactRoute = ContactRoute;
  protected readonly HomeRoute = HomeRoute;
  protected readonly AboutRoute = AboutRoute;
  protected readonly CultureRoute = CultureRoute;
  protected readonly CareersRoute = CareersRoute;
  protected readonly CookiesPolicyRoute = CookiesPolicyRoute;
  protected readonly PrivacyPolicyRoute = PrivacyPolicyRoute;
  protected readonly LegalRoute = LegalRoute;
  protected readonly NewsRoute = NewsRoute;
  public lang= this.translateService.currentLang;

  constructor(private translateService: TranslateService, private router: Router) {
    this.translateService.onLangChange.subscribe((params: any) => {
/*      console.log(params);*/
      this.lang = params.lang;
       });
  }

  changeLanguage(language: string) {
    this.lang = language;
    localStorage.setItem('_language', language);
    this.router.navigate([], {
      queryParams: {
        lang: language
      }
    });
  }
}
