import {Component} from '@angular/core';
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {TranslateModule} from "@ngx-translate/core";
import {FlipBoxComponent} from "../../components/flip-box/flip-box.component";
import {NewsItemComponent} from "../../components/news-item/news-item.component";
import {NgForOf, NgTemplateOutlet} from "@angular/common";
import {JobGroupComponent} from "../careers/job-item/job-group.component";
import {NewsItemDto} from "../../contracts/news-item.dto";
import {DataService} from "../../providers/data.service";
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, FlipBoxComponent, NewsItemComponent, NgTemplateOutlet, JobGroupComponent, NgForOf],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent {
  items: NewsItemDto[] = [];

  constructor(private dataService: DataService,
    private newsService:NewsService
  ) {
    this.items =  this.newsService.getNews().filter(x=> x.private != true);
  }

}
