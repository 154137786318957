'use strict';

export const HomeRoute = '/';
export const ContactRoute = '/contact';
export const AboutRoute = '/about';
export const CultureRoute = '/culture';
export const CareersRoute = '/careers';
export const NewsRoute = '/news';
export const CookiesPolicyRoute = '/cookies-policy';
export const PrivacyPolicyRoute = '/privacy-policy';
export const LegalRoute = '/legal';
export const HiddenPageRoute = '/26dc068c-92ad-442c-9803-75d33a7846e9';
export const PressReleasePageRoute = '/26dc068c-92ad-442c-9803-75d33a7846e1';
