import { Injectable } from "@angular/core";
import { JobItemDto } from "../contracts/jobItemDto";
import { JobGroupDto } from "../contracts/job-group.dto";

@Injectable({
    providedIn: 'root'
  })
  export class CareerService {
  items:JobGroupDto[] = [
    {
        name: "Software Development and Operations",
        items:[
            {
                title: "Java Developer",
                slug: 'java-developer',
                imgSrc: "/assets/images/release.webp"
            },
            {
                title: "Angular Developer",
                slug: 'angular-developer',
                imgSrc: "/assets/images/release.webp"
            },
            {
                title: "DevOps Consultant with German",
                slug: 'devops-german',
                imgSrc: "/assets/images/release.webp"
            },
            {
                title: ".NET Developer with Azure & IoT Knowledge",
                slug: 'net-developer-azure-iot',
                imgSrc: "/assets/images/release.webp"
            }
        ]
    }, {
      name: 'Cloud Computing',
      items: [
        {
          title:  "AWS Cloud DevOps Engineer",
          slug: 'aws-cloud-devops',
          imgSrc: "/assets/images/release.webp"
        }
      ]
    },{
      name:  "Data Science & Data Engineering",
      items: [
        {
          title:  "LLM Data Scientist",
          slug: 'llm-data-scientist',
          imgSrc: "/assets/images/release.webp"
        },  {
          title:  "Data Engineer",
          slug: 'data-engineer',
          imgSrc: "/assets/images/release.webp"
        }
      ]
    }, {
      name:  "Agile Transformation & E2E Management",
      items: [
        {
          title:  "Business Analyst with German",
          slug: 'business-analyst-german',
          imgSrc: "/assets/images/release.webp"
        }
      ]
    },{
      name:  "Product Platforms",
      items: [
        {
          title:   "Integration Designer with Mulesoft",
          slug: 'integration-designer-mulesoft',
          imgSrc: "/assets/images/release.webp"
        },
        {
          title:    "EDI Consultant",
          slug: 'edi-consultant',
          imgSrc: "/assets/images/release.webp"
        }
      ]
    }
  ];

  getItems() {
    return this.items;
  }

  getFlatList():JobItemDto[] {
    const res:JobItemDto[] = [];

    this.items.forEach(x=> {
        res.push(...x.items);
    });

    return res;
  }
}