<div class="hero-bg hero-bg-news ">
  <app-navbar />
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1>{{ 'hidden-page.description' | translate }}</h1>
    </div>
  </div>
</div>


<div class="news-container" style="margin-top: -32px;">
  <div class="app-container pt-5">
    <h3>{{ 'news.item.title-press' | translate }}</h3>
    <div *ngIf="items.length == 0" class="pb-4">
      No press release
    </div>
    <ng-template ngFor let-item [ngForOf]="items" let-i="index">
      <app-news-item [item]="item" [scrollTargetId]="'photoGallery'" />
    </ng-template>
  </div>
</div>

<div class="app-container about-values pt-5 pb-5">
  <h2>{{ 'biography.title' | translate }}</h2>
</div>
<div>
  <div class="row align-items-center g-5  pb-5  app-container">
    <div class="col-sm-12 col-xl-4 col-lg-6 bio-img">
      <img alt="Alexander Buresch" src="/assets/images/biography/alexander_buresch.webp"
        class="d-block img-fluid half-img-text bio-portrait" loading="lazy">
    </div>
    <div class="col-xl-8 col-lg-6">
      <h3 class=" lh-1 mb-3">
        Alexander Buresch - SVP & CIO BMW Group
      </h3>
      <p class="lead">
        {{ 'biography.buresch' | translate }}
      </p>
    </div>
  </div>
</div>

<div class="row flex-lg-row-reverse align-items-center g-5 pb-5 app-container  news-container ">
  <div class="col-sm-12 col-xl-4 col-lg-6 bio-img">
    <img alt="Chieri Kimura" src="/assets/images/biography/chieri_kimura.webp" class="d-block img-fluid half-img-text"
      loading="lazy">
  </div>
  <div class="col-xl-8 col-lg-6">
    <h3 class=" lh-1 mb-3">
      Chieri Kimura
      Executive Vice President of NTT DATA, Inc., CEO EUROPE & LATAM
    </h3>
    <p class="lead">
      {{ 'biography.kimura' | translate }}
    </p>
  </div>
</div>

<div>
  <div class="row align-items-center g-5  pb-5 app-container">
    <div class="col-sm-12  col-xl-4 col-lg-6 bio-img">
      <img alt="Emil Petru" src="/assets/images/biography/emil_petru.webp" class="d-block img-fluid half-img-text"
        loading="lazy">
    </div>
    <div class="col-xl-8 col-lg-6">
      <h3 class=" lh-1 mb-3">Emil Petru - CEO BMW TechWorks Romania</h3>
      <p class="lead">
        {{ 'biography.emil' | translate }}
      </p>
    </div>
  </div>
</div>

<div class="row flex-lg-row-reverse align-items-center g-5 pb-5 app-container  news-container ">
  <div class="col-sm-12 col-xl-4 col-lg-6 bio-img">
    <img alt="Maria Metz" src="/assets/images/biography/maria_metz.webp" class="d-block img-fluid half-img-text"
      loading="lazy">
  </div>
  <div class="col-xl-8 col-lg-6">
    <h3 class=" lh-1 mb-3">Maria Metz - CEO NTT DATA Romania</h3>
    <p class="lead">
      {{ 'biography.metz' | translate }}
    </p>
  </div>
</div>

<div>
  <div class="row align-items-center g-5 mb-5 pb-5 app-container">
    <div class="col-sm-12  col-xl-4 col-lg-6 bio-img">
      <img alt="Marian Haus" src="/assets/images/biography/marian_haus.webp" class="d-block img-fluid half-img-text"
        loading="lazy">
    </div>
    <div class="col-xl-8 col-lg-6">
      <h3 class=" lh-1 mb-3">Marian Haus - COO BMW TechWorks</h3>
      <p class="lead">
        {{ 'biography.haus' | translate }}
      </p>
    </div>
  </div>
</div>
<div class="app-container">
  <app-photo-gallery [images]="images" />
</div>
