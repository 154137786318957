<app-job-detail>
    <ul>
        <li>Being the guardian of data in our high-speed environment, with opportunities to shape the future of data & mobility, you will build and maintain Big Data Pipelines</li>
        <li>Ensure that data is shared in line with the information classification requirements on a need-to-know basis</li>
        <li>Perform thorough testing and data validation to ensure the accuracy of data transformations</li>
        <li>Drive the data revolution</li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Computer Science, Mathematics, Engineering or a related field</li>
        <li>Minimum of 5-7 years of experience on a similar Data Engineer role is required </li>
        <li>Above average experience/understanding of: Terraform, Python, SQL, ETL</li>
        <li>Experience and solid understanding of Docker, Linux/Unix, Big Data</li>
        <li>Familiar with Powershell/Bash, Cloud Data Hub (CDH) </li>
        <li>Relevant experience with AWS (Glue, CloudWatch, S3, Lambda, DynamoDB, Step Function and more)</li>
        <li>Datamart and Data Vault skills are needed</li>
        <li>Experience with Snowflake</li>
        <li>Excellent English verbal and written skills </li>
        <li>German communication skills will be a plus </li>
    </ul>
</app-job-detail>