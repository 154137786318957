import { Component } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'app-legal',
  standalone: true,
  imports: [TranslateModule, NavbarComponent],
  templateUrl: './legal.component.html',
  styleUrl: './legal.component.scss'
})
export class LegalComponent {

}
