import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {ModalDto} from "../contracts/modal.dto";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private messageSource = new Subject<ModalDto>();
  message$ = this.messageSource.asObservable();

  open(opts: ModalDto) {
    this.messageSource.next(opts);
  }
}
