<div class="card job-card">
  <div class="card-body" (click)="expand($event)">
    <div class="title">
      <h3>{{ job.name }}</h3>
      <a aria-label="Jobs" class="call-to-action-link-btn careers-carret" (click)="expand($event)" style="width: auto">
        <img src="/assets/images/icons/down-arrows.svg"
             style="width: 25px;height:auto"
             alt="See more"/>
      </a>
    </div>
  </div>
</div>
<div class="job-item-container" *ngIf="expanded">
  <ng-template ngFor let-item [ngForOf]="job.items" let-i="index" [ngForTrackBy]="trackByFn">
    <div class="card job-card-item">
      <div class="card-body" >
        <div class="content d-flex justify-content-between">
          <h4>{{ item.title }}</h4>
        </div>
        <button class="call-to-action-link-btn" (click)="readMore($event, item)" style="min-width: 150px">
          {{ 'button.read-button' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
