<app-job-detail>
    <ul>
        <li>Gathering and clarifying requirements from the business to understand the core problem</li>
        <li>Challenge change requests and search for alternative solutions to protect the target solution</li>
        <li>Translate requirements into proper user stories ready to be used by the development team</li>
        <li>Extract data needed from reporting requirements, map them towards data lake and conduct a data gap analysis</li>
        <li>Support of product owner to steer the feature team</li>
        <li>Documentation of functional specifications, technical and business requirements</li>
        <li>Ensuring appropriate use of IT project management tools, data governance, processes, policies, and methodologies throughout the projects to follow the given standards</li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or a related field</li>
        <li>At least 5 years of experience in IT project management, PMO, Business Analysis etc.</li>
        <li>Exceptional analytical and quantitative problem-solving skills</li>
        <li>Experience with project management tools, JIRA, Confluence, Tableau, and Qlik</li>
        <li>Experience in data modeling and designing or entity-relationship model</li>
        <li>Experience with the automotive industry and agile software development</li>
        <li>Excellent command of both spoken and written English and German</li>
    </ul>
</app-job-detail>