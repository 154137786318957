import { Component } from "@angular/core";
import { NavbarComponent } from "../../../../components/navbar/navbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { NewsDetailComponent } from "../../news-detail.component";
import { DiscoveredFileDto } from "../../../../contracts/discovered-file.dto";
import { NgForOf } from "@angular/common";
import { PhotoGallery } from "../../../../components/photo-gallery/photo-gallery.component";
import { DataService } from "../../../../providers/data.service";

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, NewsDetailComponent, NgForOf, PhotoGallery],
  templateUrl: './bmw-m4-gt4-at-bmw-techworks-romania.component.html'
})
export class BmwM4AtBmwTechworksRomania {
  public images: DiscoveredFileDto[] = [];

  constructor(
    private dataService: DataService
  ) {
    const data = this.dataService.getData();
    this.images = data?.bmw_m4_9_10_2024;
  }
}
