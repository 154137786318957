import { Injectable } from "@angular/core";
import { NewsItemDto } from "../contracts/news-item.dto";

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  items: NewsItemDto[] = [
    {
      title: "BMW M4 GT4 at BMW TechWorks Romania",
      slug: 'bmw-m4-gt4-at-bmw-techworks-romania',
      subTitle: "Subtitle",
      description: "<b>• Desc<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
      imgAlt: "BMW M4 GT4 at BMW TechWorks Romania",
      imgSrc: "/assets/images/release.webp",
      private: true
    },
    {
      title: "BMW TechWorks Romania: BMWGroup and NTT DATA accelerate digital transformation with new IT hub",
      slug: 'bmw-04-24',
      subTitle: "THU, 04 JULY 2024",
      description: "<b>• Made in Europe:</b> New Romanian IT hub develops central building blocks for IT projects in the EU<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
      imgAlt: "BMW TechWorks Romania Opening Event",
      imgSrc: "/assets/images/release.webp",
      files: [
        {
          name: "BMWGroup_and_NTT_DATA_accelerate_digital_transformation_with_new_IT_hub_1.pdf",
          title: "Download EN press release here"
        }, {
          name: "Comunicat_de_presa.pdf",
          title: "Download RO press release here"
        }
      ]
    }, {
      title: "BMW Group and NTT DATA Romania sign Joint Venture contract",
      slug: 'jv-26-06-24',
      subTitle: "TUE, 05 MARCH 2024",
      description: "• Joint Venture planned in the up-and-coming university town of Cluj-Napoca.<br/>• Start with 120 talented software developers.<br/>• Focus on the development and operation of IT solutions for the European region.",
      imgAlt: "CFO NTT Data image",
      imgSrc: "/assets/images/jointVenture.webp",
    }];


  getNews(): NewsItemDto[] {
    return this.items;
  }
}
