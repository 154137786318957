<div *ngIf="!downloading" class="file-preview" (click)="download()">
  <div>
    <img *ngIf="!useImgThumb" class="file-preview-ico" src="{{this.iconSrc}}"
         alt="{{fileName}}"/>
    <img *ngIf="useImgThumb" class="news-img-thumbnail" src="{{'files/'+this.fileName}}" alt="{{fileName}}"/>
  </div>
  <span>{{ title }}</span>
</div>
<div *ngIf="downloading">
  Downloading...
</div>
