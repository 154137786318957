import {Component, Input, TrackByFunction} from '@angular/core';
import {JobGroupDto} from "../../../contracts/job-group.dto";
import {NgForOf, NgIf} from "@angular/common";
import {JobItemDto} from "../../../contracts/jobItemDto";
import {ModalService} from "../../../services/modal.service";
import {TranslateModule} from "@ngx-translate/core";
import {NewsItemDto} from "../../../contracts/news-item.dto";
import {BlobService} from "../../../services/blob.service";
import {FileService} from "../../../services/file.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-group',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    TranslateModule
  ],
  templateUrl: './job-group.component.html'
})
export class JobGroupComponent {
  @Input() job: JobGroupDto;
  expanded: boolean;

  constructor(private modalService: ModalService,
              private fileService: FileService,
              private router:Router,
              private blobService: BlobService) {
  }

  expand(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.expanded = !this.expanded;
  }

  trackByFn: TrackByFunction<JobItemDto> = (ix, it) => {
    return it.title;
  }

  readMore(event: Event, it: JobItemDto) {
    event.preventDefault();
    event.stopPropagation();
    
    const slug = 'job/' + it.slug;
    this.router.navigateByUrl(slug); 
  }

}
