<div class="d-flex flex-column full-page-height">
  <main class="flex-grow-1">
    <router-outlet></router-outlet>
    <!--<div>Test url from env: <b> {{ this.testUrl }}</b></div>-->
  </main>
<!--  <app-file-preview [title]="'Exemplu fisier'" [fileName]="'exampleFile.pdf'"/>-->
  <app-footer/>
  <app-cookie-consent/>
  <app-modal/>
</div>
