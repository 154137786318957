<div class="hero-bg hero-bg-cookies">
  <app-navbar />
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1>{{'cookies-policy.description' | translate }}</h1>
    </div>
  </div>
</div>
<div class="app-container">
  <!--
    <h1>Lang set to : {{lang}}</h1>
    {{'cookies-policy.spHTML' | translate }}
    <div innerHTML="{{'cookies-policy.spHTML' | translate }}"></div>
    <div sp-privacy-policy-text="direct-text" sp-lang-code="{{lang}}"></div>
  -->
  <div sp-privacy-policy-text="direct-text" sp-lang-code="en"></div>
</div>
