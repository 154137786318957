import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'app-cookie-policy',
  standalone: true,
  imports:[TranslateModule, NavbarComponent],
  templateUrl: './cookie-policy.component.html',
  styleUrl: './cookie-policy.component.scss'
})
export class CookiePolicyComponent implements OnInit {
  lang: string = 'en';

  constructor() {
    const lang = localStorage.getItem('_language');
    // console.log(lang);
    if (lang !== null) {
      this.lang = lang;
    }

  }
  ngOnInit() {
  }

}
