import {Component} from '@angular/core';
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {TranslateModule} from "@ngx-translate/core";
import {FlipBoxComponent} from "../../components/flip-box/flip-box.component";
import {NewsItemComponent} from "../../components/news-item/news-item.component";
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {DataService} from "../../providers/data.service";
import {NewsItemDto} from "../../contracts/news-item.dto";
import {RouterLink} from '@angular/router';
import {AboutRoute, CareersRoute, CultureRoute} from '../../app.globals';
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink, TranslateModule, NavbarComponent, FlipBoxComponent, NewsItemComponent, NgTemplateOutlet, NgIf],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  latestNewsItem: NewsItemDto;
  protected readonly AboutRoute = AboutRoute;
  protected readonly CultureRoute = CultureRoute;
  protected readonly CareersRoute = CareersRoute;

  constructor(private newsService: NewsService) {
    const news = this.newsService.getNews().filter(x => !x.private);
    if (news && news.length > 0) {
      this.latestNewsItem = news[0];
    }
  }

}
