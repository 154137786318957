<app-job-detail>
    <ul>
        <li>Being a passionate Frontend Developer, you care about code development and review </li>
        <li>Version control using tools like Git </li>
        <li>Automated testing (unit, integration, end-to-end)  </li>
        <li>Monitoring and logging for application and infrastructure performance  </li>
        <li>Collaboration and communication with team members and stakeholders   </li>
        <li>Agile development in an iterative and incremental manner    </li>
        <li>Documentation maintenance for code     </li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or related subjects </li>
        <li>Excellent English verbal and written skills</li>
        <li>At least 5 years of experience on a similar Angular Developer role is required  </li>
        <li>Experience in Frontend development using Angular 2+ WebComponents, Angular 13, CSS, HTML5, REST  </li>
        <li>Experience in Databases using SQL  </li>
        <li>Experience in writing unit tests using Web Component Tester, Sinon, Mocha   </li>
        <li>Experience in debugging and developing for multiple browsers and devices   </li>
        <li>High analytical thinking skills and level of quality awareness   </li>
        <li>Self-motivated and a high willingness to learn    </li>
        <li>German communication skills will be a plus    </li>
    </ul>
</app-job-detail>