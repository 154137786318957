import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class BlobService {

  constructor() { }

  readBlobAsText(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }
}
