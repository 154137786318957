<div class="hero-bg hero-bg-contact">
  <app-navbar />
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1>{{'contact.description' | translate }}</h1>
    </div>
  </div>
</div>

<div class="app-container pt-4">
  <h2>{{ 'contact.content-title' | translate }}</h2>
  <p class="about-text pt-4">
    {{ 'contact.content' | translate }}
  </p>
  <div class="row mt-5">
    <div class="col-12 col-md-6 col-lg-4">
      <p class="about-subtitles">{{ 'contact.address-title' | translate }}</p>
      <p class="about-text">{{ 'contact.address' | translate }}</p>
      <p class="about-subtitles">E-mail</p>
      <p class="about-text"> <a href="mailto:contact@bmwtechworks.ro">contact&#64;bmwtechworks.ro</a></p>
    </div>
    <div class=" col-12 col-md-6 col-lg-8">
      <iframe width="100%" height="350" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=9%20Ploie%C8%99ti%20Street,%20Cluj-Napoca,%20Romania+(Address)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" style="border: 1px solid black"></iframe>
     
    </div>
    </div>
</div>

