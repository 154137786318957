<div class="hero-bg hero-bg-careers">
  <app-navbar />
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1 class="new-line">{{ 'careers.description' | translate }}</h1>
    </div>
  </div>
</div>
<div class="app-container">
  <h2>{{ 'careers.title' | translate }}</h2>
  <p class="mt-4 new-line">
    {{ 'careers.content' | translate }}
  </p>
  <div class="job-container">
    <ng-template ngFor let-item [ngForOf]="items" let-i="index" [ngForTrackBy]="trackByFn">
      <app-job-group [job]="item" />
    </ng-template>
  </div>
</div>

