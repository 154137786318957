import {Component, Input, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {FileService} from "../../services/file.service";

@Component({
  selector: 'app-file-preview',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './file-preview.component.html',
  styleUrl: './file-preview.component.scss'
})
export class FilePreviewComponent implements OnInit {
  @Input() public fileName: string;
  @Input() public title: string;
  downloading: boolean = false;
  iconSrc: string;
  useImgThumb: boolean;

  constructor(private fileService: FileService) {
  }

  ngOnInit(): void {
    if (this.fileName.endsWith('pdf')) {
      this.iconSrc = 'assets/images/icons/pdf_icon.svg';
    } else if (this.fileName.endsWith('svg') ||
      this.fileName.endsWith('jpg') ||
      this.fileName.endsWith('png')) {
      this.iconSrc = 'assets/images/icons/photo_icon.svg';
      this.useImgThumb = true;
    } else {
      this.iconSrc = 'assets/images/icons/file_icon.svg';
    }
  }

  download() {
    this.downloading = true;
    this.fileService.download(this.fileName).subscribe((f) => {
      const url = window.URL.createObjectURL(f);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.fileName; // Set the file name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      this.downloading = false;
    }, err => {
      this.downloading = false;
      alert('There was a problem downloading your file');
    });
  }
}
