import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { NavbarComponent } from "../../components/navbar/navbar.component";
import { CommonModule } from '@angular/common';
import * as baguetteBox from 'baguettebox.js';
import { NewsItemDto } from '../../contracts/news-item.dto';
import { DataService } from '../../providers/data.service';
import { NewsItemComponent } from "../../components/news-item/news-item.component";
import { NgTemplateOutlet } from "@angular/common";
import { NewsService } from '../../services/news.service';
import { DiscoveredFileDto } from '../../contracts/discovered-file.dto';
import { PhotoGallery } from '../../components/photo-gallery/photo-gallery.component';

@Component({
  selector: 'app-press-release-page',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, CommonModule, NewsItemComponent, NgTemplateOutlet, PhotoGallery],
  templateUrl: './press-release-page.component.html',
  styleUrl: './press-release-page.component.scss'
})
export class PressReleasePageComponent implements AfterViewInit {
  public images: DiscoveredFileDto[] = [];
  items: NewsItemDto[] = [];
  @ViewChild('targetComponent') targetComponent?: ElementRef<any> | undefined;

  constructor(private newsService: NewsService,
    private dataService: DataService
  ) {
    const data = this.dataService.getData();
    const news = this.newsService.getNews();
    this.items = news?.filter(x => x.private == false);
    this.images = data?.pressGallery;
  }

  ngAfterViewInit(): void {
    baguetteBox.run('.gallery');
  }

  getImgSrc(img: DiscoveredFileDto, full: boolean) {
    if (img.hasThumb && full == false)
      return img.thumbFileName;

    return img.fullSizeName;
  }

}
