<app-job-detail>
    <ul>
        <li>As a skilled AWS Platform Engineer, you will lead the design, implementation, and continuous improvement of our AI/ML platform based on Amazon SageMaker</li>
        <li>Develop and maintain CloudFormation templates for infrastructure provisioning</li>
        <li>Design and implement CI/CD pipelines for streamlined model development and deployment</li>
        <li>Manage AWS security, applying best practices using IAM and other security services </li>
        <li>Optimize cost-efficiency by leveraging spot instances, reserved instances, and other strategies</li>
        <li>Create clear technical documentation and knowledge-sharing resources</li>
        <li>Collaborate with ML researchers and data scientists to tailor the platform for their needs, more specifically to build a scalable, secure, and cost-efficient AWS environment that empowers cutting-edge machine learning development</li>
        <li>Monitor platform performance, resolve issues, and identify areas for improvement</li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or a related field</li>
        <li>Minimum 5-7 years proven experience as a DevOps Engineer </li>
        <li>Deep expertise in AWS core services (EC2, S3, IAM, VPC, CloudFormation, etc.)</li>
        <li>Experience with provisioning AWS services (Athena, EMR, Glue, Lambda) and managed platforms (SageMaker)</li>
        <li>Proficiency with infrastructure-as-code (IaC) tools like CloudFormation</li>
        <li>Strong understanding of CI/CD concepts and tools (Jenkins, CodePipeline, etc.)</li>
        <li>Experience with containerization technologies (Docker, Kubernetes)</li>
        <li>Knowledge of AI/ML frameworks (TensorFlow, PyTorch, Scikit-learn) is a significant plus</li>
        <li>Understanding of automotive business processes, systems and industry trends is nice to have</li>
        <li>Excellent English verbal and written skills </li>
        <li>German communication skills will be a plus</li>
    </ul>
</app-job-detail>