import {environment} from "../../environments/environment";

export class UrlUtil {

  static getAppUrl() {
    return environment.url;
  }

  static composeUrlRoute(...route: string[]) {
    let url = this.getAppUrl();
    for(let i = 0;i< route.length;i++) {
      const part = route[i];
      if(part.startsWith('/')) {
        url += part;
      }else {
        url += '/' + part;
      }
    }
    return url;
  }

  static getRouteParam(pathname:string, index:number) {
    const segments = pathname.split('/').filter(segment => segment.length > 0);
return segments[index];
  }
}
