import {Component, ElementRef, Input, TemplateRef} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {NewsItemDto} from "../../contracts/news-item.dto";
import {JobItemDto} from "../../contracts/jobItemDto";
import {NgForOf, NgIf} from "@angular/common";
import {FilePreviewComponent} from "../file-preview/file-preview.component";
import {TranslateModule} from "@ngx-translate/core";
import {FileService} from "../../services/file.service";
import {BlobService} from "../../services/blob.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news-item',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    FilePreviewComponent,
    TranslateModule
  ],
  templateUrl: './news-item.component.html',
  styleUrl: './news-item.component.scss'
})
export class NewsItemComponent {
  @Input() item: NewsItemDto;
  @Input() scrollTargetId?: string;

  // @Input() desc: string; can be html

  constructor(private modalService: ModalService,
              private fileService: FileService,
              private blobService: BlobService,
            private router:Router) {
  }

  readMore(event: Event, it: NewsItemDto) {
    event.preventDefault();
    event.stopPropagation();

    const slug = 'news/' + it.slug;
    this.router.navigateByUrl(slug);
  }

  scrollToComponent() {
    if (!this.scrollTargetId) return;
    const el = document.getElementById(this.scrollTargetId);
    el.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
}
