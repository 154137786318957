<app-news-detail>
<div>
    <div class="pe-3">
        <p>
            <b>Munich / Cluj-Napoca.</b>  The BMW Group is expanding its global network of IT and software hubs with the opening of a new location in Cluj-Napoca, Romania. In cooperation with NTT DATA, BMW TechWorks Romania will become the hub for European IT and software projects and will accelerate the company's digital transformation.
        </p>
        <p>
            "With BMW TechWorks Romania, we are strengthening our footprint in the EU and contributing significantly to the digital experience of our customers" explains Alexander Buresch, CIO and Senior Vice President BMW Group IT and emphasizes: "Our global IT hubs secure our Business IT and software expertise in the long term and accelerate the implementation of our IT strategy based on latest technologies such as cloud innovations and AI.”
        </p>
        <p class="pt-4">
            <b>  Partnership with NTT DATA</b>
        </p>
        <p>
            NTT DATA is a long-standing partner of the BMW Group and brings its extensive experience in agile software development and its outstanding network in the Romanian IT landscape to the joint venture.
            “Our collaboration with the BMW Group is a testament to our mutual respect and shared vision for the future of the automotive industry. By combining our strengths, we are poised to deliver innovative and transformative solutions that will benefit our customers worldwide", says Chieri Kimura, EVP of NTT DATA, Inc., CEO EMEAL.
        </p>
        <p>
            "BMW TechWorks Romania is driven by two strong partners, with the objective of becoming a reference for the digital transformation of the BMW Group’s industrial ecosystem. It is a mission of honour which NTT DATA received in the wake of a 30 plus years old valued partnership between our two companies”, underlines Maria Metz, CEO of NTT DATA Romania.
        </p>
        <p class="pt-4">

            <b>   Ideal conditions for dynamic growth</b>
        </p>
        <p>
            The joint venture will more than double its workforce to 250 developers by the end of the year. In the long term, the aim is to reach a four-digit number of employees in order to consistently expand the BMW Group's global software expertise. The dynamic growth path is also reflected in ambitious business goals: the aim is to achieve double-digit million turnover by the end of the year.
        </p>
        <p>
            Ralf Waltram, Vice President and Head of Global DevOps Hubs explains: “BMW TechWorks Romania joins a global network of IT hubs that already includes locations in Germany, South Africa, the USA, Portugal and China” and adds: “Cluj-Napoca, known for its innovation-friendly ecosystem, entrepreneurship, start-ups and high density of IT talent, offers ideal conditions for the joint venture”.
        </p>
        <p>
            Focus on IT projects in the EU
        </p>
        <p>
            The new IT hub develops central building blocks for IT projects with a focus on Europe:
        </p>
        <ul>
            <li>
                Connected Procurement: Highly complex supply chains and control systems require networked IT systems
            </li>
            <li>
                Digital Shopfloor: Modern production IT as the backbone of the BMW iFactory
            </li>
            <li>
                Direct Sales Model: Development of central IT components for the implementation of the BMW and MINI direct sales model in Europe
            </li>
        </ul>
        <p class="pt-4">


            <b>  Innovation hub Cluj-Napoca</b>
        </p>
        <p>
            Marian Haus, COO BMW TechWorks Romania: "We want to attract qualified IT talent for our rapid growth in the coming years and are therefore particularly pleased about the excellent technical education at the universities in Cluj-Napoca and the city's lively tech scene.” Emil Petru, CEO BMW Techworks Romania adds: “"We are proud to be part of the BMW Group as of today and to play a decisive role in shaping the digital transformation of the company here in Romania."
        </p>
        <p>
            In total, more than 9,400 employees worldwide already work in IT and software development for the BMW Group and its joint ventures.
        </p>
        <p class="pt-4">
            Job opportunitites at BMW TechWorks Romania can be found here:
        </p>
        <p>
            <a href="https://www.bmwtechworks.ro/careers">https://www.bmwtechworks.ro/careers</a>
        </p>
        <p class="pt-4">
            If you have any questions, please contact:
        </p>
        <p>
            <b>  BMW Group Corporate Communications</b>
        </p>
        <p class="pt-4">
            <b>Benedikt Fischer</b>
        </p>
        <p>
            Spokesperson BMW Group IT
        </p>
        <p>
            Telephone: +49 89 382-66887
        </p>
        <p>
            E-Mail: <a href=" benedikt.fischer@bmwgroup.com"> benedikt.fischer&#64;bmwgroup.com</a>
        </p>

        <p class="pt-4">
            <b>Christophe Koenig</b>
        </p>
        <p>
            Head of Communications Digital and Driving Experience
        </p>
        <p>
            Telephone: +49-151-601-56097
        </p>
        <p>
            E-Mail:<a href="christophe.koenig@bmwgroup.com">christophe.koenig&#64;bmwgroup.com</a>
        </p>
        <p>
            Media website:<a href="www.press.bmwgroup.com">www.press.bmwgroup.com </a>
        </p>
        <p>
            E-Mail:<a href="presse@bmw.de"> presse&#64;bmw.de</a>
        </p>
        <p  class="pt-4">
            <b> NTT DATA</b>
        </p>
        <p class="pt-3">
            <b>Lia Apostol </b>
        </p>
        <p>
            Head of Marketing
        </p>
        <p>
            Telephone: +40753015010
        </p>
        <p>
            E-Mail:<a href="lia.apostol@nttdata.com">lia.apostol&#64;nttdata.com</a>
        </p>
        <p class="pt-4">
            <b>Cristian Blană</b>
        </p>
        <p>
            Senior PR Executive
        </p>
        <p>
            Telephone:+40756045004
        </p>
        <p>
            E-Mail:<a href=" cristian.blana@amicom.ro"> cristian.blana&#64;amicom.ro</a>
        </p>




        <p class="pt-4">
            <b>  The BMW Group</b>
        </p>
        <p>
            With its four brands BMW, MINI, Rolls-Royce and BMW Motorrad, the BMW Group is the world’s leading premium manufacturer of automobiles and motorcycles and also provides premium financial and mobility services. The BMW Group production network comprises over 30 production sites worldwide; the company has a global sales network in more than 140 countries.

        </p>
        <p>
            In 2023, the BMW Group sold over 2.55 million passenger vehicles and more than 209,000 motorcycles worldwide. The profit before tax in the financial year 2023 was € 17.1 billion on revenues amounting to € 155.5 billion. As of 31 December 2023, the BMW Group had a workforce of 154,950 employees.
        </p>
        <p>
            The success of the BMW Group has always been based on long-term thinking and responsible action. The company set the course for the future at an early stage and consistently makes sustainability and efficient resource management central to its strategic direction, from the supply chain through production to the end of the use phase of all products.
        </p>
        <a href="https://www.bmwgroup.com/">
            www.bmwgroup.com
        </a>
        <div class="pt-5">
            <p>
                LinkedIn: <a href="https://www.linkedin.com/company/bmw-group/">https://www.linkedin.com/company/bmw-group/</a>
            </p>
            <p>
                YouTube:<a href="http://www.youtube.com/BMWGroupView">http://www.youtube.com/BMWGroupView </a>
            </p>
            <p>
                Facebook: <a href="http://www.facebook.com/BMWGroup"> http://www.facebook.com/BMWGroup </a>
            </p>
            <p>
                Instagram: <a href=" https://www.instagram.com/bmwgroup">  https://www.instagram.com/bmwgroup</a>
            </p>
        </div>


        <p class="pt-5">
            <b>About NTT DATA</b>
        </p>
        <p>
            NTT DATA is a $30+ billion trusted global innovator of business and technology services. We serve 75% of the Fortune Global 100 and are committed to helping clients innovate, optimize and transform for long-term success.
        </p>
        <p>
            We invest over $3.6 billion each year in R&D to help organizations and society move confidently and sustainably into the digital future. As a Global Top Employer, we have diverse experts in more than 50 countries and a robust partner ecosystem of established and start-up companies.
        </p>
        <p>

            Our services include business and technology consulting, data and artificial intelligence, industry solutions, as well as the development, implementation and management of applications, infrastructure, and connectivity. We are also one of the leading providers of digital and AI infrastructure in the world. NTT DATA is part of NTT Group and headquartered in Tokyo.
            <span>
                Visit us at  <a href="https://ro.nttdata.com/">  https://ro.nttdata.com/  </a>
            </span>
        </p>

        <div class="pt-4">
            <p>
                LinkedIn: <a href="https://www.linkedin.com/company/nttdataro/ ">https://www.linkedin.com/company/nttdataro/ </a>
            </p>
            <p>
                Facebook: <a href="https://www.faceboock.com/NTTDATARO ">https://www.faceboock.com/NTTDATARO  </a>
            </p>
            <p>
                Instagram: <a href="https://www.instagram.com/nttdataromania">https://www.instagram.com/nttdataromania </a>
            </p>
        </div>
    </div>
</div>
</app-news-detail>
