import {Component, OnInit} from '@angular/core';
import {Inject, makeStateKey, Optional, PLATFORM_ID, TransferState} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {TranslateService} from '@ngx-translate/core';
import {FooterComponent} from "./components/footer/footer.component";
import {NavbarComponent} from "./components/navbar/navbar.component";
import {CookieConsentComponent} from "./components/cookie-consent/cookie-consent.component";
import {RouteMetaService} from "./services/route-meta.service";
import {environment} from "../environments/environment";
import {ModalComponent} from "./components/modal/modal.component";
import {DATA_FROM_SERVER, DataFromServer} from "./providers/data-from-server";
import {WebsiteSiteDataDto} from "./contracts/website-site-data.dto";
import {isPlatformServer} from "@angular/common";
import {DataService} from "./providers/data.service";
import {FilePreviewComponent} from "./components/file-preview/file-preview.component";
import { Event_04_24_Component } from './pages/news-detail/long-descriptions/event-04-24/event-04-24.component';

const websiteData = makeStateKey<WebsiteSiteDataDto>('WebsiteData');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TranslateModule, FooterComponent, NavbarComponent, CookieConsentComponent, ModalComponent, FilePreviewComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  testUrl: string;

  constructor(private translateService: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private routeMeta: RouteMetaService,
              private transferState: TransferState,
              private dataService: DataService,
              @Inject(PLATFORM_ID) private platformId: any,
              @Optional() @Inject(DATA_FROM_SERVER) private dataFromServer?: DataFromServer) {
    this.testUrl = environment.url;
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    this.router.events.subscribe((event) => {
      if (isPlatformServer(this.platformId)) return;

      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    this.route.queryParamMap
      .pipe(takeUntilDestroyed())
      .subscribe((params: any) => {
        const lang = params.get('lang');
        if (lang) {
          /*          console.log("Default lang app comp in if: " + lang);*/
          this.translateService.use(lang);
        }
      });
    this.routeMeta.subscribeAndUpdate(this.router);
    console.log(websiteData);
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(websiteData, dataFromServer);
    } else if (this.transferState.hasKey(websiteData)) {
      const wsData = this.transferState.get(websiteData, undefined);
      console.log(wsData);
      this.dataService.setData(wsData);
    }

  }
 
  ngOnInit() {
    if (isPlatformServer(this.platformId)) return;

    const lang = localStorage.getItem('_language');
    /*    console.log("Default lang app comp: " + lang);*/
    if (lang !== null) {
      this.translateService.use(lang);
    }
  }

  useLanguage(language: string) {
    this.router.navigate([], {
      queryParams: {
        lang: language
      }
    });
  }
}
