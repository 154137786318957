<div class="hero-bg hero-bg-news">
  <app-navbar/>
  <header class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container  hero-text  mx-0 ">
      <div>
        <h1 class="new-line">{{ 'news.description' | translate }}</h1>
      </div>
    </div>
  </header>
</div>

<div class="app-container about-values pt-4 pb-5">
  <h2>{{ 'news.content-title' | translate }}</h2>
  <p class="about-text pt-4">
    {{ 'news.content' | translate }}
  </p>
</div>


<div class="news-container">

  <div class="app-container pt-5">
    <h3>{{ 'news.item.title' | translate }}</h3>
    <ng-template ngFor let-item [ngForOf]="items" let-i="index">
      <app-news-item [item]="item"/>
    </ng-template>
  </div>
</div>

