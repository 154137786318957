<div class="hero-bg hero-bg-about ">
  <app-navbar />
  <div class="d-flex flex-column justify-content-center hero-header">
    <div class="app-container">
      <h1 class ="new-line">{{ 'about.description' | translate }}</h1>
</div>
  </div>
</div>
<div class="app-container about-values pt-4 ">
  <h2 class="mb-4">{{ 'about.content-title' | translate }}</h2>
    <p *ngIf="lang=='en' || lang=='de' " class="about-subtitles">
      {{ 'about.content-subtitle' | translate }}
    </p>
  <p class="about-text new-line">
    {{ 'about.content' | translate }}
  </p>
</div>

