<app-news-detail>
    <section id="main" class="article-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7 ">
                    <article id="articleTask">
                        <div class="wysiwyg-content">
    
                            <p class="P68B1DB1-Standard2"><strong>Munich / Cluj-Napoca.</strong> The <strong>BMW Group</strong> and <strong>NTT DATA Romania</strong> have signed an agreement to establish a <strong>Joint Venture (JV) in Romania</strong>. The BMW Group is thus continuing to expand its global network for its corporate IT and is securing talent and experience in the software sector. The execution of the JV contract is subject to review and approval by the relevant authorities.</p>
                            <p><span>The JV benefits from NTT DATA’s many years of experience in agile software development and its excellent network in the local Romanian IT community. The new location in Romania is intended to support the BMW Group IT in Europe and drive forward IT-projects and innovations for production, development, human resources, sales and BMW Financial Services.</span></p>
                            <p class="P68B1DB1-Standard2"><em>“With NTT DATA, we are relying on another strong partner that has an excellent network in a lively technology region and enables us to build up additional software competencies quickly and in a targeted manner. In collaboration with companies like NTT DATA we can establish strong IT hubs and thereby give an answer to the shortage of IT experts,” </em><strong>says Alexander Buresch, CIO and Senior Vice President of</strong> <strong>BMW Group IT.</strong></p>
                            <p><span><em>“We are proud to announce our plans to open a new software development center for software solutions in Cluj-Napoca together with BMW Group. This collaboration aims to assist the carmaker in leading the digital transformation,"</em></span><strong><span> says Maria Metz, CEO at NTT DATA Romania.</span></strong></p>
                            <p class="P68B1DB1-Standard3"><span>The JV is to be set up in Cluj-Napoca in Romania. The university town offers a strong ecosystem for innovation with solid entrepreneurship, startups and a high number of talents in the tech scene. This creates high potential for long-term growth of the JV. The JV is expected to <strong>employ around 250 software developers</strong> at the end of 2024 and plans to grow to a four-digit number of employees by 2027.</span></p>
                            <p class="P68B1DB1-Standard3"><span class="ui-provider">The BMW Group and NTT DATA have been collaborating across a wide array of projects for over 30 years. The signing of the JV contract marks the next big step in this partnership.</span></p>
                            <p class="P68B1DB1-Standard2"><strong>Global footprint in IT and software development.</strong></p>
                            <p class="P68B1DB1-Standard2"><strong></strong>The BMW Group is already well advanced in digitizing the entire company and its internal and external processes. For several years now, IT &amp; software hubs in Germany, the USA, South Africa, India, Portugal (Joint Venture Critical TechWorks) and China (LingYue Digital IT Co. Ltd. and BA TechWorks) have been strengthening the premium manufacturer's software expertise. They contribute significantly to the development and operation of innovative IT solutions for the BMW Group. <span class="normaltextrun" style="background: white; color: black;">A total of over 9,400 employees worldwide are already working in IT and software development for the BMW Group and the Joint Ventures.</span></p>
                            <p class="P68B1DB1-Standard2"><strong>&nbsp;</strong></p>
                            <p class="Fliesstext"><strong><span>BMW Group Corporate Communications</span></strong></p>
                            <p class="Fliesstext"><span style="color: black;">Martin Tholund, Press Spokesperson Digital Car, Electronics and Software, Technology Partnerships, Digital Products and Services</span></p>
                            <p class="Fliesstext"><span style="color: black;">Telephone: +49-151-601-77126</span></p>
                            <p class="Fliesstext"><span style="color: black;">E-mail: </span><span><a href="mailto:Martin.Tholund@bmwgroup.com"><span>Martin.Tholund&#64;bmwgroup.com</span></a></span></p>
                            <p class="Fliesstext"><span>&nbsp;</span></p>
                            <p class="Fliesstext"><span style="color: black;">Christophe Koenig, Head of BMW Group IT, Digital and Driving Experience Communications</span></p>
                            <p class="Fliesstext"><span style="color: black;">Telephone: +49-176-601-56097</span></p>
                            <p class="Fliesstext"><span style="color: black;">E-mail: </span><span><a href="mailto:Christophe.Koenig@bmwgroup.com"><span>Christophe.Koenig&#64;bmwgroup.com</span></a></span><span style="color: black;"> </span></p>
                            <p class="Fliesstext"><span>&nbsp;</span></p>
                            <p class="Fliesstext"><span>Internet: </span><span><a rel="noopener noreferrer" href="http://www.press.bmwgroup.com/global" target="_blank"><span>www.press.bmwgroup.com/global</span></a></span></p>
                            <p class="Fliesstext"><span>E-mail: </span><span><a href="mailto:presse@bmw.de"><span>presse&#64;bmw.de</span></a></span></p>
                            <p class="Fliesstext"><span>&nbsp;</span></p>
                            <p class="Fliesstext"><strong><span>NTT DATA Romania</span></strong></p>
                            <p class="Fliesstext"><span style="color: black;">Lia Apostol, Head of Marketing </span></p>
                            <p class="Fliesstext"><span style="color: black;">E-mail: </span><span><a href="mailto:lia.apostol@nttdata.com"><span>lia.apostol&#64;nttdata.com</span></a></span></p>
                            <p class="P68B1DB1-Standard2"><span class="normaltextrun" style="background: white; color: black;"></span></p>
    
                        </div>
    
                    </article>
                </div>
                <div class="col-lg-5 mt-5">
                    <aside class="article-sidebar" id="articleSidebar">
                        <section class="wysiwyg-section ">
    
                            <div class="wysiwyg-content">
                                <h2>About BMW Group</h2>
    
                                <p><span>With its four brands BMW, MINI, Rolls-Royce and BMW Motorrad, the BMW Group is the world’s leading premium manufacturer of automobiles and motorcycles and also provides premium financial and mobility services. The BMW Group production network comprises over 30 production sites worldwide; the company has a global sales network in more than 140 countries.</span></p>
                                <p><span>In 2022, the BMW Group sold nearly 2.4 million passenger vehicles and more than 202,000 motorcycles worldwide. The profit before tax in the financial year 2022 was €&nbsp;23.5&nbsp;billion on revenues amounting to €&nbsp;142.6&nbsp;billion. As of 31 December 2022, the BMW Group had a workforce of 149,475 employees.</span></p>
                                <p><span>The success of the BMW Group has always been based on long-term thinking and responsible action. The company set the course for the future at an early stage and consistently makes sustainability and efficient resource management central to its strategic direction, from the supply chain through production to th</span><span style="color: black;">e end of the use phase of all products.&nbsp;</span></p>
    
                            </div>
    
                            <div class="container-fluid">
                                <div class="text-center">
                                </div>
                            </div>
                        </section>
                        <div class="wysiwyg-content">
                            <h2>About NTT DATA Romania</h2>
    
                            <p><span>NTT DATA Romania operates as a key player in the IT market, offering a wide range of services and solutions to clients across various industries. With a focus on innovation, quality, reliability and customer satisfaction, the company continues to drive technological advancements while fostering the growth and success of its clients both locally and globally.</span></p>
                            <p><span>As part of NTT DATA Group, with clients in over 50 countries, the company combines global reach and the world's most robust technology with local expertise. This synergy empowers clients, as well as society, to move confidently into the digital future.</span></p>
                            <p><span>Benefiting from a strong presence in the main university centers of Romania, NTT DATA Romania boasts a diverse team of highly skilled professionals who offer a comprehensive range of services that span across various domains including application development and maintenance, infrastructure services, cloud solutions, digital transformation, and outsourcing services. This diverse skillset enables the company to offer a comprehensive range of services tailored to meet the specific needs of its clients and have a proven track record of successfully executing complex IT initiatives across different industries including automotive, manufacturing, banking, retail, telecommunications, and more.</span></p>
                            <p><span>With their global footprint complemented by local expertise, NTT DATA Romania is the go-to partner for businesses seeking to leverage technology for growth and innovation.</span></p>
    
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </section>
    </app-news-detail>