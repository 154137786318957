<app-job-detail>
    <ul>
        <li> Being a passionate LLM Data Scientist, you will define and enable together with the Data Engineer the proper Data models as input for the LLM models</li>
        <li> Instantiate and operate the LLM solution based on provided Cloud AI services and enhance with own services if needed</li>
        <li> Setup concepts and models for the LLM to train, operate and keep it up to date for a daily use of daily based data</li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or a related field</li>
        <li>Minimum of 3 years’ experience as a LLM Engineer based on Cloud services in AI projects</li>
        <li>Strong dedication to Quality (modelling, code, functional operation)</li>
        <li>Strong skills in LLM and its techniques</li>
        <li>Proven technical and functional leadership and motivation to shape the project regarding the LLM management. </li>
        <li>Excellent English verbal and written skills </li>
        <li>German communication skills will be a plus</li>
    </ul>
</app-job-detail>