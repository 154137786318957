import {WebsiteSiteDataDto} from "../contracts/website-site-data.dto";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  static data: WebsiteSiteDataDto;


  setData(data: WebsiteSiteDataDto) {
    DataService.data = data;
  }

  getData() {
    return DataService.data;
  }
}
