import {Meta, Title} from "@angular/platform-browser";
import {SeoDto} from "../contracts/seo.dto";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs";
import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class RouteMetaService {
  constructor(private title: Title,
              private activatedRoute: ActivatedRoute,
              @Inject(DOCUMENT) private doc: Document,
              private meta: Meta) {
  }

  subscribeAndUpdate(router: Router) {
    router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event: any) => {
        // console.log('Got seo data event', event);
        this.updateSeo(event?.seo as SeoDto);
      });
  }

  updateSeo(data: SeoDto) {
    if (!data) return;
    if (data.title) {
      this.updateTitle(data.title);
    }
    if (data.description)
      this.updateDescription(data.description);
    if (data.ogUrl)
      this.updateOgUrl(data.ogUrl);
    if (data.url) {
      this.updateUrl(data.url);
    }
    if (data.ogImage)
      this.updateOgImage(data.ogImage);
    this.updateType('website');
    this.updateOgTitle(data.title);
    this.updateOgDesc(data.description);
    this.updateSiteName('BMW Techworks');
    this.updateLocale('en-US');
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({name: 'description', content: desc});
  }

  updateType(type: string) {
    this.meta.updateTag({property: 'og:type', content: type});
  }

  updateSiteName(sn: string) {
    this.meta.updateTag({property: 'og:site_name', content: sn});
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({property: 'og:url', content: url});
  }

  isCanonicalLink(link: HTMLLinkElement) {
    return link.rel === 'canonical';
  }

  updateUrl(url: string) {
    const headLinks = this.doc.head.getElementsByTagName('link');
    let canonical: HTMLLinkElement = this.doc.createElement('link');

    for (let i = 0; i < headLinks.length; i++) {
      if (this.isCanonicalLink(headLinks[i])) {
        canonical = headLinks[i];
        break;
      }
    }

    let isNew: boolean = false;
    if (!canonical.href) {
      isNew = true;
    }

    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', url);

    if (isNew)
      this.doc.head.appendChild(canonical);
  }

  updateOgTitle(url: string) {
    this.meta.updateTag({property: 'og:title', content: url});
  }

  updateOgDesc(url: string) {
    this.meta.updateTag({property: 'og:description', content: url});
  }

  updateOgImage(url: string) {
    this.meta.updateTag({property: 'og:image', content: url});
  }

  updateLocale(url: string) {
    this.meta.updateTag({property: 'og:locale', content: url});
  }

}
