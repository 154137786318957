import { NgForOf, NgTemplateOutlet } from "@angular/common";
import { Component } from "@angular/core";
import { NavbarComponent } from "../../../../components/navbar/navbar.component";
import { FlipBoxComponent } from "../../../../components/flip-box/flip-box.component";
import { NewsItemComponent } from "../../../../components/news-item/news-item.component";
import { JobGroupComponent } from "../../../careers/job-item/job-group.component";
import {TranslateModule} from "@ngx-translate/core";
import { NewsDetailComponent } from "../../news-detail.component";

@Component({
    selector: 'app-jv-26-06-24',
    standalone: true,
    imports: [TranslateModule, NavbarComponent, 
      FlipBoxComponent, NewsItemComponent, NgTemplateOutlet, JobGroupComponent, NgForOf, NewsDetailComponent],
    templateUrl: './jv-26-06-24.component.html' 
  })
  export class Jv_26_06_24_Component{

  }