import { NgForOf, NgTemplateOutlet } from "@angular/common";
import { Component } from "@angular/core";
import { NavbarComponent } from "../../../../components/navbar/navbar.component";
import { FlipBoxComponent } from "../../../../components/flip-box/flip-box.component";
import { JobGroupComponent } from "../../job-item/job-group.component";
import {TranslateModule} from "@ngx-translate/core"; 
import { JobDetailComponent } from "../job-detail.component";

@Component({
    selector: 'app-edi-consultant-job',
    standalone: true,
    imports: [TranslateModule, NavbarComponent, 
      FlipBoxComponent, JobDetailComponent, NgTemplateOutlet, JobGroupComponent, NgForOf],
    templateUrl: './edi-consultant-job.component.html' 
  })
  export class JobEditConsultantComponent{

  }