import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {Subscription} from "rxjs";
import {ModalDto} from "../../contracts/modal.dto";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnDestroy, OnInit {
  modalSubscription: Subscription;
  modalService: ModalService;
  opts: ModalDto;

  constructor(modalService: ModalService) {
    this.modalService = modalService;
  }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.message$.subscribe((next) => {
      this.opts = next;
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    this.closeDialog();
  }

  ngOnDestroy() {
    this.modalSubscription.unsubscribe();
  }

  closeDialog() {
    this.opts = null;
  }

  preventClose(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

}
