import { Component } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [TranslateModule, NavbarComponent],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})

export class ContactComponent {
}
