import { Routes } from '@angular/router';
import { ContactComponent } from "./pages/contact/contact.component";
import { HomeComponent } from "./pages/home/home.component";
import { SeoDto } from "./contracts/seo.dto";
import { AboutComponent } from './pages/about/about.component';
import { CareersComponent } from './pages/careers/careers.component';
import { CultureComponent } from './pages/culture/culture.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { LegalComponent } from './pages/legal/legal.component';
import { UrlUtil } from "./utils/url.util";
import { NewsComponent } from './pages/news/news.component';
import { PressReleasePageComponent } from './pages/press-release-page/press-release-page.component';
import { Event_04_24_Component } from './pages/news-detail/long-descriptions/event-04-24/event-04-24.component';
import { Jv_26_06_24_Component } from './pages/news-detail/long-descriptions/jv-26-06-24/jv-26-06-24.component';
import { JobJavaDevComponent } from './pages/careers/listings/java-dev/java-dev.component';
import { JobAngularComponent } from './pages/careers/listings/angular-job/angular-job.component';
import { JobDevopsGermanComponent } from './pages/careers/listings/devops-german/devops-german-job.component';
import { JobAwsDevopsComponent } from './pages/careers/listings/aws-cloud-devops/aws-cloud-devops.component';
import { JobLlmDataScientistComponent } from './pages/careers/listings/llm-data-scientist/llm-data-scientist.component';
import { JobDataEngineerComponent } from './pages/careers/listings/data-engineer/data-engineer.component';
import { JobIntegrationDesignerMulesoftComponent } from './pages/careers/listings/integration-designer-mulesoft/integration-designer-mulesoft.component';
import { JobBusinessAnalystGermanComponent } from './pages/careers/listings/business-analyst-german/business-analyst-german.component';
import { JobEditConsultantComponent } from './pages/careers/listings/edi-consultant/edi-consultant-job.component';
import { BmwM4AtBmwTechworksRomania } from './pages/news-detail/long-descriptions/bmw-m4-gt4-at-bmw-techworks-romania/bmw-m4-gt4-at-bmw-techworks-romania.component';

export const routes: Routes = [
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      seo: {
        title: 'Contact',
        description: 'Contact page for BMW Group TechWorks Romania',
        ogUrl: UrlUtil.composeUrlRoute('contact'),
        url: UrlUtil.composeUrlRoute('contact'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/contact-page.webp')
      } as SeoDto
    }
  },
  {
    path: '',
    component: HomeComponent,
    data: {
      seo: {
        title: 'BMW Group TechWorks Romania',
        description: 'BMW Group TechWorks Romania leads automotive innovation in partnership with the BMW Group, shaping the future of motion',
        ogUrl: UrlUtil.composeUrlRoute(''),
        url: UrlUtil.composeUrlRoute(''),
        ogImage: UrlUtil.composeUrlRoute('assets/images/homepage.webp')
      } as SeoDto
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      seo: {
        title: 'About Us',
        description: 'Introducing BMW Group TechWorks Romania, the European IT hub of BMW',
        ogUrl: UrlUtil.composeUrlRoute('about'),
        url: UrlUtil.composeUrlRoute('about'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/about-page.webp')
      } as SeoDto
    }
  },
  {
    path: 'careers',
    component: CareersComponent,
    data: {
      seo: {
        title: 'Careers',
        description: 'Browse all our available job openings',
        ogUrl: UrlUtil.composeUrlRoute('careers'),
        url: UrlUtil.composeUrlRoute('careers'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/careers-page.webp')
      } as SeoDto
    }
  },
  {
    path: 'culture',
    component: CultureComponent,
    data: {
      seo: {
        title: 'Our Culture and Values ',
        description: 'Discover our opportunities and  take the next career step with BMW TechWorks Romania',
        ogUrl: UrlUtil.composeUrlRoute('culture'),
        url: UrlUtil.composeUrlRoute('culture'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/culture-page.webp')
      } as SeoDto
    }
  },
  {
    path: 'news',
    component: NewsComponent,
    data: {
      seo: {
        title: 'BMW Group TechWorks Romania - News',
        description: 'Read new stories about the company and learn more about current technological trends and innovations',
        ogUrl: UrlUtil.composeUrlRoute('news'),
        url: UrlUtil.composeUrlRoute('news'),
        ogImage: UrlUtil.composeUrlRoute('assets/images/news-page.webp')
      } as SeoDto
    }
  },
  {
    path: 'news/bmw-04-24',
    component: Event_04_24_Component
  },
  {
    path: 'news/jv-26-06-24',
    component: Jv_26_06_24_Component
  },
  {
    path: 'news/bmw-m4-gt4-at-bmw-techworks-romania',
    component: BmwM4AtBmwTechworksRomania
  },
  {
    path: 'job/java-developer',
    component: JobJavaDevComponent
  },
  {
    path: 'job/angular-developer',
    component: JobAngularComponent
  },
  {
    path: 'job/devops-german',
    component: JobDevopsGermanComponent
  },
  {
    path: 'job/net-developer-azure-iot',
    component: JobDevopsGermanComponent
  },
  {
    path: 'job/aws-cloud-devops',
    component: JobAwsDevopsComponent
  },
  {
    path: 'job/llm-data-scientist',
    component: JobLlmDataScientistComponent
  },
  {
    path: 'job/data-engineer',
    component: JobDataEngineerComponent
  }, {
    path: 'job/business-analyst-german',
    component: JobBusinessAnalystGermanComponent
  }, {
    path: 'job/integration-designer-mulesoft',
    component: JobIntegrationDesignerMulesoftComponent
  }, {
    path: 'job/edi-consultant',
    component: JobEditConsultantComponent
  },
  {
    path: 'cookies-policy',
    component: CookiePolicyComponent,
    data: {
      seo: {
        title: 'Privacy & Cookies Policy',
        description: 'Privacy & Cookies Policy',
      } as SeoDto
    }
  },
  {
    path: 'legal',
    component: LegalComponent,
    data: {
      seo: {
        title: 'Legal information',
        description: 'Legal information',
      } as SeoDto
    }
  },
  {
    path: 'press',
    component: PressReleasePageComponent,
    data: {
      seo: {
        title: 'Press Release Page',
        description: 'Press Release Page Desc',
      } as SeoDto
    }
  }
];
