<app-job-detail>
    <ul>
        <li>Work in an exciting environment with internal and external feature teams according to the Agile Working Model</li>
        <li>
            Be responsible for project management, for connecting external partners for new vehicle projects or series and coordinate our internal and external contacts
        </li>
        <li>Be responsible for the further development of EDI processes for international plants </li>
        <li>Advise our departments on process design and being the first point of contact for all topics in the EDI environment  </li>
        <li>Take responsibility and drive our projects within the framework of EDI.Next, PKT and cloud migration </li>
        <li>Together with the team, you ensure the secure operation of our EDI system</li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or a related field</li>
        <li>Minimum 5-7 years of experience in a similar position </li>
        <li>Project management experience and proven knowledge of logistics processes</li>
        <li>Ideally, EDI and/or SAP knowledge </li>
        <li>Ideally, first experience in the cloud environment</li>
        <li>Practical experience operating production-critical systems</li>
        <li>Experience with agile software development tools (e.g. Jira, Confluence)</li>
        <li>Analytical thinking and strong problem-solving, negotiation, prioritization and decision-making skills</li>
        <li>Excellent English verbal and written skills </li>
        <li>German communication skills will be a plus </li>
    </ul>
</app-job-detail>