/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.11.1
 * @url https://github.com/feimosi/baguetteBox.js
 */
!function (e, t) {
  "use strict";

  "function" == typeof define && define.amd ? define(t) : "object" == typeof exports ? module.exports = t() : e.baguetteBox = t();
}(this, function () {
  "use strict";

  var r,
    l,
    u,
    c,
    d,
    f = '<svg width="44" height="60"><polyline points="30 10 10 30 30 50" stroke="rgba(255,255,255,0.5)" stroke-width="4"stroke-linecap="butt" fill="none" stroke-linejoin="round"/></svg>',
    g = '<svg width="44" height="60"><polyline points="14 10 34 30 14 50" stroke="rgba(255,255,255,0.5)" stroke-width="4"stroke-linecap="butt" fill="none" stroke-linejoin="round"/></svg>',
    p = '<svg width="30" height="30"><g stroke="rgb(160,160,160)" stroke-width="4"><line x1="5" y1="5" x2="25" y2="25"/><line x1="5" y1="25" x2="25" y2="5"/></g></svg>',
    b = {},
    v = {
      captions: !0,
      buttons: "auto",
      fullScreen: !1,
      noScrollbars: !1,
      bodyClass: "baguetteBox-open",
      titleTag: !1,
      async: !1,
      preload: 2,
      animation: "slideIn",
      afterShow: null,
      afterHide: null,
      onChange: null,
      overlayBackgroundColor: "rgba(0,0,0,.8)"
    },
    m = {},
    h = [],
    o = 0,
    n = !1,
    i = {},
    a = !1,
    y = /.+\.(gif|jpe?g|png|webp)/i,
    w = {},
    k = [],
    s = null,
    x = function (e) {
      -1 !== e.target.id.indexOf("baguette-img") && j();
    },
    E = function (e) {
      e.stopPropagation ? e.stopPropagation() : e.cancelBubble = !0, D();
    },
    C = function (e) {
      e.stopPropagation ? e.stopPropagation() : e.cancelBubble = !0, X();
    },
    B = function (e) {
      e.stopPropagation ? e.stopPropagation() : e.cancelBubble = !0, j();
    },
    T = function (e) {
      i.count++, 1 < i.count && (i.multitouch = !0), i.startX = e.changedTouches[0].pageX, i.startY = e.changedTouches[0].pageY;
    },
    N = function (e) {
      if (!a && !i.multitouch) {
        e.preventDefault ? e.preventDefault() : e.returnValue = !1;
        var t = e.touches[0] || e.changedTouches[0];
        40 < t.pageX - i.startX ? (a = !0, D()) : t.pageX - i.startX < -40 ? (a = !0, X()) : 100 < i.startY - t.pageY && j();
      }
    },
    L = function () {
      i.count--, i.count <= 0 && (i.multitouch = !1), a = !1;
    },
    A = function () {
      L();
    },
    P = function (e) {
      "block" === r.style.display && r.contains && !r.contains(e.target) && (e.stopPropagation(), Y());
    };
  function S(e) {
    if (w.hasOwnProperty(e)) {
      var t = w[e].galleries;
      [].forEach.call(t, function (e) {
        [].forEach.call(e, function (e) {
          W(e.imageElement, "click", e.eventHandler);
        }), h === e && (h = []);
      }), delete w[e];
    }
  }
  function F(e) {
    switch (e.keyCode) {
      case 37:
        D();
        break;
      case 39:
        X();
        break;
      case 27:
        j();
        break;
      case 36:
        !function t(e) {
          e && e.preventDefault();
          return M(0);
        }(e);
        break;
      case 35:
        !function n(e) {
          e && e.preventDefault();
          return M(h.length - 1);
        }(e);
    }
  }
  function H(e, t) {
    if (h !== e) {
      for (h = e, function s(e) {
        e = e || {};
        for (var t in v) b[t] = v[t], "undefined" != typeof e[t] && (b[t] = e[t]);
        l.style.transition = l.style.webkitTransition = "fadeIn" === b.animation ? "opacity .4s ease" : "slideIn" === b.animation ? "" : "none", "auto" === b.buttons && (("ontouchstart" in window) || 1 === h.length) && (b.buttons = !1);
        u.style.display = c.style.display = b.buttons ? "" : "none";
        try {
          r.style.backgroundColor = b.overlayBackgroundColor;
        } catch (n) {}
      }(t); l.firstChild;) l.removeChild(l.firstChild);
      for (var n, o = [], i = [], a = k.length = 0; a < e.length; a++) (n = J("div")).className = "full-image", n.id = "baguette-img-" + a, k.push(n), o.push("baguetteBox-figure-" + a), i.push("baguetteBox-figcaption-" + a), l.appendChild(k[a]);
      r.setAttribute("aria-labelledby", o.join(" ")), r.setAttribute("aria-describedby", i.join(" "));
    }
  }
  function I(e) {
    b.noScrollbars && (document.documentElement.style.overflowY = "hidden", document.body.style.overflowY = "scroll"), "block" !== r.style.display && (U(document, "keydown", F), i = {
      count: 0,
      startX: null,
      startY: null
    }, q(o = e, function () {
      z(o), V(o);
    }), R(), r.style.display = "block", b.fullScreen && function t() {
      r.requestFullscreen ? r.requestFullscreen() : r.webkitRequestFullscreen ? r.webkitRequestFullscreen() : r.mozRequestFullScreen && r.mozRequestFullScreen();
    }(), setTimeout(function () {
      r.className = "visible", b.bodyClass && document.body.classList && document.body.classList.add(b.bodyClass), b.afterShow && b.afterShow();
    }, 50), b.onChange && b.onChange(o, k.length), s = document.activeElement, Y(), n = !0);
  }
  function Y() {
    b.buttons ? u.focus() : d.focus();
  }
  function j() {
    b.noScrollbars && (document.documentElement.style.overflowY = "auto", document.body.style.overflowY = "auto"), "none" !== r.style.display && (W(document, "keydown", F), r.className = "", setTimeout(function () {
      r.style.display = "none", document.fullscreen && function e() {
        document.exitFullscreen ? document.exitFullscreen() : document.mozCancelFullScreen ? document.mozCancelFullScreen() : document.webkitExitFullscreen && document.webkitExitFullscreen();
      }(), b.bodyClass && document.body.classList && document.body.classList.remove(b.bodyClass), b.afterHide && b.afterHide(), s && s.focus(), n = !1;
    }, 500));
  }
  function q(t, n) {
    var e = k[t],
      o = h[t];
    if (void 0 !== e && void 0 !== o) if (e.getElementsByTagName("img")[0]) n && n();else {
      var i = o.imageElement,
        a = i.getElementsByTagName("img")[0],
        s = "function" == typeof b.captions ? b.captions.call(h, i) : i.getAttribute("data-caption") || i.title,
        r = function d(e) {
          var t = e.href;
          if (e.dataset) {
            var n = [];
            for (var o in e.dataset) "at-" !== o.substring(0, 3) || isNaN(o.substring(3)) || (n[o.replace("at-", "")] = e.dataset[o]);
            for (var i = Object.keys(n).sort(function (e, t) {
                return parseInt(e, 10) < parseInt(t, 10) ? -1 : 1;
              }), a = window.innerWidth * window.devicePixelRatio, s = 0; s < i.length - 1 && i[s] < a;) s++;
            t = n[i[s]] || t;
          }
          return t;
        }(i),
        l = J("figure");
      if (l.id = "baguetteBox-figure-" + t, l.innerHTML = '<div class="baguetteBox-spinner"><div class="baguetteBox-double-bounce1"></div><div class="baguetteBox-double-bounce2"></div></div>', b.captions && s) {
        var u = J("figcaption");
        u.id = "baguetteBox-figcaption-" + t, u.innerHTML = s, l.appendChild(u);
      }
      e.appendChild(l);
      var c = J("img");
      c.onload = function () {
        var e = document.querySelector("#baguette-img-" + t + " .baguetteBox-spinner");
        l.removeChild(e), !b.async && n && n();
      }, c.setAttribute("src", r), c.alt = a && a.alt || "", b.titleTag && s && (c.title = s), l.appendChild(c), b.async && n && n();
    }
  }
  function X() {
    return M(o + 1);
  }
  function D() {
    return M(o - 1);
  }
  function M(e, t) {
    return !n && 0 <= e && e < t.length ? (H(t, b), I(e), !0) : e < 0 ? (b.animation && O("left"), !1) : e >= k.length ? (b.animation && O("right"), !1) : (q(o = e, function () {
      z(o), V(o);
    }), R(), b.onChange && b.onChange(o, k.length), !0);
  }
  function O(e) {
    l.className = "bounce-from-" + e, setTimeout(function () {
      l.className = "";
    }, 400);
  }
  function R() {
    var e = 100 * -o + "%";
    "fadeIn" === b.animation ? (l.style.opacity = 0, setTimeout(function () {
      m.transforms ? l.style.transform = l.style.webkitTransform = "translate3d(" + e + ",0,0)" : l.style.left = e, l.style.opacity = 1;
    }, 400)) : m.transforms ? l.style.transform = l.style.webkitTransform = "translate3d(" + e + ",0,0)" : l.style.left = e;
  }
  function z(e) {
    e - o >= b.preload || q(e + 1, function () {
      z(e + 1);
    });
  }
  function V(e) {
    o - e >= b.preload || q(e - 1, function () {
      V(e - 1);
    });
  }
  function U(e, t, n, o) {
    e.addEventListener ? e.addEventListener(t, n, o) : e.attachEvent("on" + t, function (e) {
      (e = e || window.event).target = e.target || e.srcElement, n(e);
    });
  }
  function W(e, t, n, o) {
    e.removeEventListener ? e.removeEventListener(t, n, o) : e.detachEvent("on" + t, n);
  }
  function G(e) {
    return document.getElementById(e);
  }
  function J(e) {
    return document.createElement(e);
  }
  return [].forEach || (Array.prototype.forEach = function (e, t) {
    for (var n = 0; n < this.length; n++) e.call(t, this[n], n, this);
  }), [].filter || (Array.prototype.filter = function (e, t, n, o, i) {
    for (n = this, o = [], i = 0; i < n.length; i++) e.call(t, n[i], i, n) && o.push(n[i]);
    return o;
  }), {
    run: function K(e, t) {
      return m.transforms = function n() {
        var e = J("div");
        return "undefined" != typeof e.style.perspective || "undefined" != typeof e.style.webkitPerspective;
      }(), m.svg = function o() {
        var e = J("div");
        return e.innerHTML = "<svg/>", "http://www.w3.org/2000/svg" === (e.firstChild && e.firstChild.namespaceURI);
      }(), m.passiveEvents = function i() {
        var e = !1;
        try {
          var t = Object.defineProperty({}, "passive", {
            get: function () {
              e = !0;
            }
          });
          window.addEventListener("test", null, t);
        } catch (n) {}
        return e;
      }(), function a() {
        if (r = G("baguetteBox-overlay")) return l = G("baguetteBox-slider"), u = G("previous-button"), c = G("next-button"), void (d = G("close-button"));
        (r = J("div")).setAttribute("role", "dialog"), r.id = "baguetteBox-overlay", document.getElementsByTagName("body")[0].appendChild(r), (l = J("div")).id = "baguetteBox-slider", r.appendChild(l), (u = J("button")).setAttribute("type", "button"), u.id = "previous-button", u.setAttribute("aria-label", "Previous"), u.innerHTML = m.svg ? f : "&lt;", r.appendChild(u), (c = J("button")).setAttribute("type", "button"), c.id = "next-button", c.setAttribute("aria-label", "Next"), c.innerHTML = m.svg ? g : "&gt;", r.appendChild(c), (d = J("button")).setAttribute("type", "button"), d.id = "close-button", d.setAttribute("aria-label", "Close"), d.innerHTML = m.svg ? p : "&times;", r.appendChild(d), u.className = c.className = d.className = "baguetteBox-button", function n() {
          var e = m.passiveEvents ? {
              passive: !1
            } : null,
            t = m.passiveEvents ? {
              passive: !0
            } : null;
          U(r, "click", x), U(u, "click", E), U(c, "click", C), U(d, "click", B), U(l, "contextmenu", A), U(r, "touchstart", T, t), U(r, "touchmove", N, e), U(r, "touchend", L), U(document, "focus", P, !0);
        }();
      }(), S(e), function s(e, a) {
        var t = document.querySelectorAll(e),
          n = {
            galleries: [],
            nodeList: t
          };
        return w[e] = n, [].forEach.call(t, function (e) {
          a && a.filter && (y = a.filter);
          var t = [];
          if (t = "A" === e.tagName ? [e] : e.getElementsByTagName("a"), 0 !== (t = [].filter.call(t, function (e) {
            if (-1 === e.className.indexOf(a && a.ignoreClass)) return y.test(e.href);
          })).length) {
            var i = [];
            [].forEach.call(t, function (e, t) {
              var n = function (e) {
                  e.preventDefault ? e.preventDefault() : e.returnValue = !1, H(i, a), I(t);
                },
                o = {
                  eventHandler: n,
                  imageElement: e
                };
              U(e, "click", n), i.push(o);
            }), n.galleries.push(i);
          }
        }), n.galleries;
      }(e, t);
    },
    show: M,
    showNext: X,
    showPrevious: D,
    hide: j,
    destroy: function e() {
      !function n() {
        var e = m.passiveEvents ? {
            passive: !1
          } : null,
          t = m.passiveEvents ? {
            passive: !0
          } : null;
        W(r, "click", x), W(u, "click", E), W(c, "click", C), W(d, "click", B), W(l, "contextmenu", A), W(r, "touchstart", T, t), W(r, "touchmove", N, e), W(r, "touchend", L), W(document, "focus", P, !0);
      }(), function t() {
        for (var e in w) w.hasOwnProperty(e) && S(e);
      }(), W(document, "keydown", F), document.getElementsByTagName("body")[0].removeChild(document.getElementById("baguetteBox-overlay")), w = {}, h = [], o = 0;
    }
  };
});