<div [id]="id">
  <div class="jumbotron">
    <h1 class="text-center">Photo gallery</h1>
  </div>

  <div class="row gallery">
    <div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let img of images">
      <figure>
        <a [href]="getImgSrc(img, true)">
          <img [src]="getImgSrc(img, false)" width="480" height="480" class="thumbnail-image grayscale">
        </a>
      </figure>
    </div>
  </div>

</div>
