<app-job-detail>
    <ul>
        <li>As an Integration Designer with MuleSoft, you will help gearing up the ultimate customer experience using cutting-edge technology from MuleSoft and Salesforce</li>
        <li>
            Work as part of the Cluj feature team, together with other world-wide teams, and collaborate with architects and experts from our Community of Practice to address the integration challenges that are part of the digital transformation vision
        </li>
        <li> Designing Application Programming Interface (API) layers, data model and API specifications </li>
        <li> Developing APIs using Mulesoft platform components including API Manager, CloudHub, AnyPoint Studio, Dataweave and AnyPoint Messaging Queue (MQ)</li>
        <li> Developing solution features following MuleSoft development best practices, ensuring solution performance and system scalability </li>
        <li> Testing and improving performance of the APIs in terms of response time, reliability and resource usage </li>
        <li> Writing Dataweave expressions to transform data, Control message content, flow, and processing by selecting and implementing appropriate connectors, routers, scopes, and filters </li>
        <li> Designing and implement comprehensive error handling strategies for applications </li>
        <li> Driving continuous integration and delivery of APIs using DevOps tools </li>
        <li> Works closely with other integration engineers to ensure consistency and quality of solutions and knowledge sharing </li>
    </ul>
    <b>What should you bring along </b>
    <ul>
        <li>University Degree in Engineering, Computer Science or a related field</li>
        <li>Minimum 3-5 years of experience in Java development</li>
        <li>Minimum 2 years of experience in designing, developing and delivering application solutions for enterprise level development and integration projects</li>
        <li>Minimum 1 year of experience in MuleSoft development</li>
        <li>MuleSoft Certified Developer/Architect certification is advantageous</li>
        <li>Experience in Mule ESB, Anypoint Studio, API Management and CloudHub</li>
        <li>Experience in Anypoint Platform strategy & implementations that help build application network using API-led connectivity and ensure a quality solution</li>
        <li>Good understanding of integration patters</li>
        <li>Experience in Service Oriented Architecture (SOA), REST and web services</li>
        <li>Solution-oriented and analytical thinking, proactive approach</li>
        <li>Strong communication skills, team-oriented, analytical, and structured work approach</li>
        <li>Excellent English verbal and written skills </li>
        <li>German communication skills will be a plus</li>
    </ul>
</app-job-detail>