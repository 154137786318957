<nav class="navbar navbar-expand-md fixed-top navbar-dark  navbar-custom d-block" aria-label="Main navigation">
  <div class="container-fluid app-container nav-topmenu">
    <div>
      <a [routerLink]="HomeRoute" class="bmw-group-container" aria-label="Home">
        <img alt="BMW Techworks Romania Logo" class="header-logo bmw-group-tech"  src="/assets/images/BMWTC_logo-landscape.svg" />
      </a>
    </div>
    <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation" style="box-shadow:none !important;">
      <svg class="ham hamRotate ham1 bmw-hamburger-menu" viewBox="0 0 100 100" onclick="this.classList.toggle('ham-active')">
        <path class="line top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
        <path class="line middle"
              d="m 30,50 h 40" />
        <path class="line bottom"
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
      </svg>
    </button>

    <div class="navbar-right-logos">
      <div class="d-flex align-items-center">
        <img alt="Mini Logo" class="header-logo logo-mini" src="/assets/images/logo-bmw-mini.webp" />
        <img alt="NTT Data Logo" class="header-logo logo-ntt" src="/assets/images/logo-nttdata_cropped.webp" />
      </div>
    </div>
  </div>


  <div class=" w-100  offcanvas-collapse app-container" id="bmwcollapse">
    <div class="nav-submenu ">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="navbar-item ps-0">
          <a aria-label="About" class="navbar-link underline-animation" [routerLink]="AboutRoute" [routerLinkActive]="'active'">{{ 'navbar.about' | translate }}</a>
        </li>
        <li class="navbar-item">
          <a aria-label="Culture" class="navbar-link underline-animation" [routerLink]="CultureRoute" [routerLinkActive]="'active'">{{ 'navbar.culture' | translate }}</a>
        </li>
        <li class="navbar-item">
          <a aria-label="Careers" class="navbar-link underline-animation" [routerLink]="CareersRoute" [routerLinkActive]="'active'">{{ 'navbar.careers' | translate }}</a>
        </li>
        <li class="navbar-item">
          <a aria-label="News" class="navbar-link underline-animation" [routerLink]="NewsRoute" [routerLinkActive]="'active'">{{ 'navbar.news' | translate }}</a>
        </li>
        <li class="navbar-item">
          <a aria-label="Contact" class="navbar-link underline-animation" [routerLink]="ContactRoute" [routerLinkActive]="'active'">{{ 'navbar.contact' | translate }}</a>
        </li>
      </ul>
      <div class="language-outer-container">
        <div class="language-inner-container">
          <div class="bmw-language">
            <span class="bmw-language-item underline-animation" (click)="changeLanguage('en')" [ngClass]="lang=='en'?'active':''">EN</span>
          </div>
          <div class="bmw-language">
            <span class="bmw-language-item underline-animation" (click)="changeLanguage('de')"  [ngClass]="lang=='de'?'active':''">DE</span>
          </div>
          <div class="bmw-language">
            <span class="bmw-language-item underline-animation" (click)="changeLanguage('ro')"  [ngClass]="lang=='ro'?'active':''">RO</span>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</nav>

