import { Component } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { CareersRoute } from '../../app.globals';
import { RouterLink } from "@angular/router";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-culture',
  standalone: true,
  imports: [TranslateModule, NavbarComponent, RouterLink, CommonModule],
  templateUrl: './culture.component.html',
  styleUrl: './culture.component.scss'
})
export class CultureComponent {
  protected readonly CareersRoute = CareersRoute;
  public lang = this.translateService.currentLang;
  constructor(private translateService: TranslateService, private router: Router) {
    this.translateService.onLangChange.subscribe((params: any) => {
      // console.log("Language: " + this.lang);
      this.lang = params.lang;
    });
  }

}
